/**
 * @author Irakli Kandelaki
 */

import type { ContentDataImage } from "@/ts/interfaces/contentData.interface";
interface Item {
    image?: ContentDataImage[];
    mobile_image?: ContentDataImage[];

}
/**
 * @summary Get mobile image else return desktop image.
 */

export default (item: Item): string => {

    if (item?.mobile_image) {
        return item?.mobile_image[0]?.url;
    } else {
        return item?.image[0]?.devices?.mobile;

    }
}
