/**
 * @author Irakli Kandelaki
 */

import { ContentDataListItem } from "@/ts/interfaces/contentData.interface";

/**
 * @summary Set alt tag from CMS on images.
 */

export default (content: ContentDataListItem, contentTitle?: string, imageTitle?: string): string => {
    const title: string = !imageTitle ? (content?.image?.length ? content?.image[0].title.title : "") : imageTitle;
    if (!title) {
        const getTitleFromContent = !contentTitle ? content?.title : contentTitle;
        return getTitleFromContent;
    } else {
        return title;

    }

}
